// Run after everything is loaded
(function () {
    window.addEventListener("load", function load() {
        window.removeEventListener("load", load, false);
        // Payload
        decrypt();
    }, false);
}());

// Decrypts Base64, removes the first four chars from cipher
// cipher scheme: "[4 random chars that will be removed][real base 64 encoded text]"
function decrypt() {
    "use strict";

    // Declare constants
    var CLS_DECRYPT_ME = ".decrypt-me";
    var ATTR_CIPHER = "data-cipher";
    var ATTR_TARGET = "data-target";

    // Get nodes
    var nodes = document.querySelectorAll(CLS_DECRYPT_ME);

    // If empty, return
    if (!nodes) {
        return;
    }

    // Run over nodes
    nodes.forEach(function ($node) {
        // Loop over attributes
        var curAttrNum = "";
        while ($node.hasAttribute(ATTR_CIPHER + curAttrNum)) {
            // build attr-names
            var curAttrCipher = ATTR_CIPHER + curAttrNum;
            var curAttrTarget = ATTR_TARGET + curAttrNum;

            // get attr values
            var cipher = $node.getAttribute(curAttrCipher);
            var target = "body";
            if ($node.hasAttribute(curAttrTarget)) {
                target = $node.getAttribute(curAttrTarget);
            }

            // remove cipher attr's
            $node.removeAttribute(curAttrCipher);
            $node.removeAttribute(curAttrTarget);

            // decrypt
            var clearText = atob(cipher.substr(4));

            // write to document
            if (target.toLowerCase() === "body") {
                $node.innerHTML = clearText;
            } else {
                $node.setAttribute(target, clearText);
            }

            // Increase loop-index
            if (curAttrNum === "") {
                curAttrNum = 2;
            } else {
                curAttrNum++;
            }
        }
    });
}